import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import {jwtToken} from "./App";

export default function RouteMap(props) {
  return (
    <Routes>
      <Route
        path='/console'
        element={<Home state={props.state} set={props.stateUpdate} jwtToken={jwtToken} />}
      />
    </Routes>
  )
}
