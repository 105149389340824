import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { configuration } from 'connected-sdk-js'
import { theme } from 'connected-react-components'
import App from './App'
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

// Central configuration
configuration.configure()

root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App navigate={useNavigate} />
    </ThemeProvider>
  </Router>
)
