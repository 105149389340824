export default {
            Auth: {
                region: 'us-east-1',
                userPoolId: 'us-east-1_5RrsvjfnG',
                userPoolWebClientId: '240cnpbuvi0gitfoo7fv479gjk',
                oauth: {
                    domain: 'smart-app-broker-console-us1-dev.auth.us-east-1.amazoncognito.com',
                    scope: ['email', 'profile', 'openid'],
                    redirectSignIn: 'https://smart-app-broker.dev.bd-mms.com/console',
                    redirectSignOut: 'https://smart-app-broker.dev.bd-mms.com/console',
                    responseType: 'code',
                    options: {
                        AdvancedSecurityDataCollectionFlag: false
                    }
                }
            }
        }